import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import Pagination from "../../components/common/Pagination";
import { useEffect, useState } from "react";
import GridTableHeader from "../../components/common/table/GridTableHeader";
import { PersonaIcon } from "../../assets/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SearchInput } from "../../components/Form";
import { CancelButton } from "../../components/Button";
import { fetchData } from "../../services/fetch";
import { useQuery } from "react-query";
import { storeErrorModal } from "../../services/storage";
import { useDispatch } from "react-redux";
import { setSelectTitle } from "../../slices/titleReducer";

const TitleSelect = () => {
  const contents = translations.titleSelect;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setHideSideBar] = useOutletContext();

  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [paginatedata, setPaginatedata] = useState({
    total_count: 0,
    page_count: 0,
    current_page_amount: 0,
    current_page: 1,
  });
  const [page, setPage] = useState(1);

  const fetchTitles = (page = 0) => {
    return fetchData({
      url: filter
        ? "api/persona_group?filter=" + filter + "&page=" + page
        : "api/persona_group?page=" + page,
    });
  };

  const { isLoading, data: response } = useQuery(
    ["get-titles", page, filter],
    () => fetchTitles(page),
    {
      keepPreviousData: true,
      onSuccess: async (response) => {
        paginatedata.total_count = response.data.total_count;
        paginatedata.page_count = response.data.page_count;
        if (response.data.current_page * 10 > response.data.total_count) {
          paginatedata.current_page_amount = response.data.total_count;
        } else {
          paginatedata.current_page_amount = response.data.current_page * 10;
        }
        paginatedata.current_page = response.data.current_page;
      },
      onError: (error) => {
        storeErrorModal(error.message);
      },
    }
  );

  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (page !== 1) {
        setPage((p) => 1);
        setFilter((f) => search);
      } else {
        setFilter((f) => search);
      }
    }
  };

  const handlePagination = (paginateData) => {
    setPaginatedata(paginateData);
    setPage(paginateData.current_page);
  };

  const tableHeaderItem = [
    {
      headerName: strings.formatString(contents.id),
      width: "12%",
      className: "text-start",
    },
    {
      headerName: strings.formatString(contents.name),
      width: "21%",
      className: "text-start pl-40-px",
    },
    {
      headerName: strings.formatString(contents.no_of_type),
      width: "15%",
      className: "text-end",
    },
    {
      headerName: strings.formatString(contents.no_of_persona),
      width: "16%",
      className: "text-end",
    },
    {
      headerName: strings.formatString(contents.createdAt),
      width: "16%",
      className: "text-end",
    },
    {
      headerName: strings.formatString(contents.action),
      width: "20%",
      className: "text-center",
    },
  ];

  useEffect(() => {
    setHideSideBar(true);
  }, [setHideSideBar]);

  if (isLoading) {
    return <em>Loading...</em>;
  }

  const handleSelect = (title) => {
    dispatch(setSelectTitle(title));
    navigate("/personas/create");
  };

  return (
    <ContentWrapper
      titletopmarign="true"
      title={strings.formatString(contents.title)}
      topSpace={
        <div className="text-white pb-5-px d-flex justify-content-between h-100">
          {/* Search Box */}
          {/* <form className="text-white px-2 pb-5-px d-flex justify-content-between h-100"> */}
          <div onKeyDown={enterSearch} style={{ maxWidth: "80%" }}>
            <SearchInput
              placeholder={strings.formatString(contents.search_placeholder)}
              // className="w-600-px"
              width="551.52px"
              height="100%"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              required
            />
          </div>
          {/* </form> */}
          {/* Create Button */}
          <CancelButton
            text={contents.cancel_btn}
            width="173px"
            height="40px"
            onClick={() => navigate("/personas")}
          />
        </div>
      }
      bottomSpace={
        <Pagination onChange={handlePagination} paginate_data={paginatedata} />
      }
      noOverFlow="ture"
      marginLeft="12px"
    >
      <div className="h-100 w-100">
        <div className="table-responsive hide-scroll custom-table w-100">
          <table>
            <GridTableHeader tableHeaderItem={tableHeaderItem} />
            <tbody className="text-white body-font overflow-auto hide-scroll h-80 text-center mt-1">
              {response.data?.groups.map((row, i) => (
                <tr key={i}>
                  <td className="font-ethno-light fs-14-px text-start">
                    {row.group_no}
                  </td>
                  <td className="fs-15-px text-start">{row.name}</td>
                  <td className="font-ethno-light fs-14-px text-end">
                    {row.persona_type_count ?? 0}
                  </td>
                  <td className="font-ethno-light fs-14-px text-end">
                    {row.persona_token_count ?? 0}
                  </td>
                  <td className="font-ethno-light fs-13-px text-end">
                    {row.updated_at.split("T")[0]}
                  </td>
                  <td className="font-abj-choki d-flex justify-content-end align-items-center">
                    <button
                      className="action-btn d-flex justify-content-end align-items-center btn fs-12-px text-end"
                      onClick={() => handleSelect(row)}
                    >
                      <PersonaIcon
                        className="op-1 mr-3-px"
                        width={23.34}
                        height={20.21}
                      />
                      <span>{contents.select_btn}</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default TitleSelect;
