// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: {
    title: "タイトル",
    persona: "PERSONA",
    published: "公開済",
    unpublished: "未公開",
  },
  seedSetting: {
    title: "シード設定",
    labelconvert:
      "ペルソナからアルカナへの変換時、乱数から以下の計算式に従いseed値を算出。",
    labelrandom: "乱数テーブル要素数",
    labelmagnification: "倍率",
    labelprobability: "確率",
    labelformat: "Seed値＝乱数テーブルから抽選した倍率×トークンの総合値",
    labelexpectedmultiple: "期待倍率",
    labelformula: "（期待倍率=Σ[k=1, n]倍率×確率）",
    labeltotal:
      '合計確率は <span class="text-primary fs-25-px">100%</span>になる必要があります。',
    labeltotalprobability: `合計確率`,
    labellackprobability: `足りない確率`,
  },
  group: {
    title: "グループリスト",
    placeholdersearch: "グループ名やグループ番号にて検索",
    groupname: "グループ名",
    groupnumber: "グループ番号",
    numberofpersonas: "ペルソナ数",
    createddate: "作成日",
    action: "アクション",
  },
  type: {
    title: "タイプリスト",
    placeholdersearch: "タイプ名やタイプ番号にて検索",
    typename: "タイプ名",
    typenumber: "タイプ番号",
    groupname: "グループ名",
    createddate: "作成日",
    numberofpersonas: "ペルソナ数",
    action: "アクション",
  },
  management: {
    title: "ペルソナ管理",
    placeholdersearch: "ユーザー名やウォレットアドレスで検索",
    typename: "タイプ名",
    typenumber: "タイプ番号",
    groupname: "グループ名",
    createddate: "作成日",
    numberofpersonas: "ペルソナ数",
    action: "アクション",
  },
  users: {
    title: "ユーザー管理",
    user_name_number: "ユーザーNo／ユーザー名",
    wallet_addres: "ウォレットアドレス",
    number_of_hold_personas: "ペルソナ保有数",
    amount_in_jp: "金額/JPY",
    registration_date: "ユーザー登録日",
  },
  button: {
    create: "作成",
  },
  menu: {
    dashboard: "ダッシュボード",
    title: "タイトル",
    persona: "ペルソナ",
    update_history: "アップデート履歴",
    marketplace: "マーケットプレイス",
    user_management: "ユーザー管理",
    setting: "設定",
    signout: "サインアウト",
  },
  titles: {
    title: "タイトルリスト",
    placeholdersearch: "タイトル名やタイトルIDで検索",
    button: "タイトル新規作成",
    id: "タイトルID",
    name: "タイトル名",
    no_of_type: "ペルソナ種類数",
    no_of_persona: "ペルソナ数",
    updatedAt: "タイトル更新日",
    action: "アクション",
    setting: "タイトル設定",
  },
  titleSetting: {
    title: "タイトル設定",
    name: "タイトル名",
    id: "タイトルID",
    id_secondary: "自動出力",
    value: "AURA ／Gia Change",
    value_secondary: "アルカナジェネレーターを呼び出す値",
    value_placeholder: "数値を入力",
    custom_parameter: "カスタムパラメータ1",
    custom_parameter_secondary:
      "タイトルの配下となる全ペルソナに対して任意のパラメータを設定",
    parameter_type: "パラメータの型",
    parameter_type_secondary: "（整数, 実数, 文字列）",
    parameter_type_placeholder: "データ型を選択",
    parameter_name: "パラメータ名",
    parameter_name_placeholder: "○○力など",
    parameter_unit: "パラメータの単位",
    parameter_unit_secondary: "単位は無くても可",
    parameter_unit_placeholder: "mile, kg など",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    delete_btn: "タイトル削除",
  },
  titleCreate: {
    title: "タイトル新規作成",
    name: "タイトル名",
    name_placeholder: "グループ名やサービス名など",
    id: "タイトルID",
    id_secondary: "自動出力",
    value: "AURA ／Gia Change",
    value_secondary: "アルカナジェネレーターを呼び出す値",
    value_placeholder: "数値を入力",
    custom_parameter: "カスタムパラメータ1",
    custom_parameter_secondary:
      "タイトルの配下となる全ペルソナに対して任意のパラメータを設定",
    parameter_type: "パラメータの型",
    parameter_type_secondary: "（整数, 実数, 文字列）",
    parameter_type_placeholder: "データ型を選択",
    parameter_name: "パラメータ名",
    parameter_name_placeholder: "○○力など",
    parameter_unit: "パラメータの単位",
    parameter_unit_secondary: "単位は無くても可",
    parameter_unit_placeholder: "mile, kg など",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
  },
  personas: {
    title: "ペルソナリスト",
    placeholdersearch: "ペルソナ名やトークンIDで検索",
    button: "ペルソナ新規作成",
    no: "ペルソナNo",
    name: "ペルソナ名",
    type_name: "タイトル名",
    published: "公開数 / ペルソナ数",
    price: "価格 / JPY",
    action: "アクション",
    detail: "ペルソナ詳細",
  },
  personaDetail: {
    title: "ペルソナ詳細",
    cancel_btn: "キャンセル",
    update_btn: "アップデート",
    title_name: "タイトル名",
    token_id: "トークンID",
    token_id_secondary: "自動出力",
    name: "ペルソナ名",
    creator_name: "クリエイター名",
    quantity: "QTY（発行数）",
    published: "マーケットプレイス公開数",
    price: "現在の販売価格",
    image: "画像",
    parameter: "カスタムパラメータ 1",
    parameter_secondary: "※タイトルで定義したパラメータ",
    leadership: "統率力",
    political_power: "政治力",
    leg_strength: "脚力",
    charisma: "カリスマ性",
    description: "Description（ペルソナの説明文）",
    description_secondary:
      "ペルソナに対する説明文を記載。カード状の裏側に表示される",
    description_placeholder: "ペルソナに対する説明文を記載",
    status: "Status（ベースパラメータ）",
    status_secondary:
      "ペルソナの持つ基礎パラメータ。ANMコストやドローチェーンなどに影響",
    cost: "必要ANM（アニマ）コスト",
    cost_secondary: "※ベースパラメータによって必要なANM変化",
    absorb: {
      label: "Absorb（食べる）",
      contract: "Absorb contract",
    },
    absorb_secondary: "アルカナを吸収するスマートコントラクトのON／OFF",
    draw_chain: {
      label: "Draw chain（ドローチェーン）",
      contract: "Draw chain Contract",
      id: "Draw chain ID / code",
      name: "ドローチェーン名",
      name_placeholder: "チャッピーワンのフードボーナス",
      required: "ドローに必要な",
      required_secondary: "ドローチェーンが実行できるようになる FOR の値",
      no: "ドローできる回数",
      no_secondary: "回",
      description: "ドロー対象の説明",
      description_placeholder:
        "ネコダービーのアイテム「スーパーフード」が10個もらえる！",
      placeholder: "値を入力",
    },
    draw_chain_secondary:
      "ペルソナを使って特定のアイテムを引き出すスマートコントラクトのON／OFF",
    element: {
      label: "Elements（属性）",
    },
    element_secondary: "ペルソナの持つ属性・カードゲームなどに影響",
    place_of_birth: "Birthplace（出生地）",
    place_of_birth_secondary: "スクエアキーによって定義",
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルソナの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルソナの持つ特殊能力を自由に定義",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルソナに称号を自由に定義",
    parameter_placeholder: "文字列入力",
  },
  personaMarketPreview: {
    title: "マーケットプレイス公開設定",
    public_btn: "公開／売出",
    cancel_btn: "キャンセル",
    private_marketplace: "プライベートマーケットプレイス",
    published_on: "OCTILLION（オクティリオン） での公開",
    sale_volume: "販売数",
    sale_volume_placeholder: "販売数を入力",
    current_sale: "現在の販売数",
    disclosed: "未公開数",
    element: {
      label: "Elements（属性別）",
    },
    selling_price: {
      label: "販売価格（売りのオファー）",
      jp: "JPY（日本円）",
      anima: "ANIMA（アニマ）",
    },
    total: "合計",
  },
  personaUp: {
    title: "内部値アップデート",
    cancel_btn: "キャンセル",
    update_btn: "アップデート実行",
    title_name: "タイトル名",
    token_id: "トークンID",
    token_id_secondary: "自動出力",
    custom_parameter: {
      label: "カスタムパラメータ 1",
      current_value: "現在の値",
      changed_value: "変更後の値",
      leadership: "統率力",
      political_power: "政治力",
      leg_strength: "脚力",
      charisma: "カリスマ性",
    },
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルソナの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルソナの持つ特殊能力を自由に定義",
    parameter_placeholder: "文字列入力",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルソナに称号を自由に定義",
  },
  titleSelect: {
    title: "タイトル選択",
    search_placeholder: "タイトル名やタイトルIDで検索",
    cancel_btn: "キャンセル",
    id: "タイトルID",
    name: "タイトル名",
    no_of_type: "ペルソナ種類数",
    no_of_persona: "ペルソナ数",
    createdAt: "作成日",
    action: "アクション",
    select_btn: "このタイトルを選択",
  },
  personaCreate: {
    title: "ペルソナ新規発行",
    search_placeholder: "タイトル名やタイトルIDで検索",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    issue_btn: "ペルソナ発行",
    title_name: "タイトル名",
    name: "ペルソナ名",
    name_placeholder: "ペルソナ名を入力",
    token_id_secondary: "自動出力",
    creator_name: "クリエイター名",
    creator_name_placeholder: "クリエイター名を入力",
    quantity: "QTY（発行数）",
    quantity_placeholder: "発行数を入力",
    image: "画像",
    parameter: "カスタムパラメータ 1",
    parameter_secondary: "※タイトルで定義したパラメータ",
    // leadership: "統率力",
    // political_power: "政治力",
    // leg_strength: "脚力",
    // charisma: "カリスマ性",
    description: "Description（ペルソナの説明文）",
    description_secondary:
      "ペルソナに対する説明文を記載。カード状の裏側に表示される",
    description_placeholder: "ペルソナに対する説明文を記載",
    status: "Status（ベースパラメータ）",
    status_secondary:
      "ペルソナの持つ基礎パラメータ。ANMコストやドローチェーンなどに影響",
    cost: "必要ANM（アニマ）コスト",
    cost_secondary: "※ベースパラメータによって必要なANM変化",
    cost_current: "現在のANM残高",
    absorb: {
      label: "Absorb（食べる）",
      contract: "Absorb contract",
    },
    absorb_secondary: "アルカナを吸収するスマートコントラクトのON／OFF",
    draw_chain: {
      label: "Draw chain（ドローチェーン）",
      contract: "Draw chain Contract",
      id: "Draw chain ID / code",
      name: "ドローチェーン名",
      name_placeholder: "ドローチェーン名を入力",
      required: "ドローに必要な",
      required_secondary: "ドローチェーンが実行できるようになる FOR の値",
      no: "ドローできる回数",
      no_secondary: "回",
      description: "ドロー対象の説明",
      description_placeholder:
        "ネコダービーのアイテム「スーパーフード」が10個もらえる！",
      placeholder: "値を入力",
    },
    draw_chain_secondary:
      "ペルソナを使って特定のアイテムを引き出すスマートコントラクトのON／OFF",
    element: {
      label: "Elements（属性）",
    },
    element_secondary: "ペルソナの持つ属性・カードゲームなどに影響",
    place_of_birth: "Birthplace（出生地）",
    place_of_birth_secondary: "スクエアキーによって定義",
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルソナの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルソナの持つ特殊能力を自由に定義",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルソナに称号を自由に定義",
    upload_btn: "アップロード",
    parameter_placeholder: "文字列入力",
    validation: {
      imageSize: "ファイルサイズを2MB以下にする必要があります",
    },
  },
  marketplace: {
    title: "マーケットプレイス情報",
    address: "Address",
    public_status: "公開ステータス",
    sales: "売上高",
    primary_on_sale: "プライマリ販売中",
    total_selling_offer_amount: "売りオファーの総額",
    secondary_open: "セカンダリ公開中",
    this_month_sale: "今月の売上 ",
    primary_sales: "プライマリ販売",
    secondary_commission: "セカンダリ手数料",
    total_so_far: "これまでの累計",
  },
};
