import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import { useContext, useEffect, useState } from "react";
import {
  ABSIcon,
  BottomLessAbyssIcon,
  // CopyIcon,
  CrimsonFlameIcon,
  DFTIcon,
  // DrawChainIcon,
  DropOfHeavenIcon,
  // EatIcon,
  EverGreenTreeIcon,
  EXPIcon,
  FORIcon,
  GoldenLightIcon,
  INTIcon,
  MNDIcon,
  PenIcon,
  SolidRockIcon,
  UnrivaledSteelIcon,
  ImgPlaceholderIcon,
} from "../../assets/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SelectInput, TextInput } from "../../components/Form";
import Button, { CancelButton } from "../../components/Button";
// import { Switch } from "../../components/Switch";
import { ConfirmModal, InfoModal, LoadingModal } from "../../components/Modal";
import { useSelector } from "react-redux";
import { fetchData, fetchFormData } from "../../services/fetch";
import { useMutation, useQuery } from "react-query";
import { storeErrorModal } from "../../services/storage";
import { AuthContext } from "../../context/authContext";
import { useMemo } from "react";

const elements = [
  "heavenly_dew_element", //drop_of_heaven_element
  "crimson_flame_element", //red_lotus_flame_element
  "golden_light_element",
  "eternal_tree_element", //everlasting_tree_element
  "peerless_steel_element", //warriors_steel_element
  "unfathomable_abyss_element",
  "adamantine_rock_element", //solid_rocks_element
];

const status = [
  "abs_parameter",
  "def_parameter",
  "month_parameter",
  "int_parameter",
  "exp_parameter",
];

const PersonaCreate = () => {
  const contents = translations.personaCreate;
  const navigate = useNavigate();
  const [, setHideSideBar] = useOutletContext();
  const { selectedTitle } = useSelector((state) => state.titles);
  const { user } = useContext(AuthContext);

  const [showKeepModal, setShowKeepModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [values, setValues] = useState({
    persona_type_name: "",
    creator: "",
    persona_qty: "",
    description: "",
    for_parameter: "",
    abs_parameter: 20,
    def_parameter: 20,
    month_parameter: 20,
    int_parameter: 20,
    exp_parameter: 20,
    eternal_tree_element: "",
    crimson_flame_element: "",
    adamantine_rock_element: "",
    peerless_steel_element: "",
    heavenly_dew_element: "",
    golden_light_element: "",
    unfathomable_abyss_element: "",
    peerage: "",
  });
  const [customParameter2, setCustomParameter2] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [customParameter3, setCustomParameter3] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [customParameter1, setCustomParameter1] = useState([]);
  const [parameters1, setParameters1] = useState();
  const [image, setImage] = useState({ local: "", upload: null });
  const [isLoadProgress, setIsLoadProgress] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [validModal, setValidModal] = useState({ show: false, message: "" });
  const [cost, setCost] = useState(null);

  const statusValues = useMemo(() => {
    const values = {};
    for (let i = 20; i <= 4180; i += 20) {
      values[i] = i;
    }
    return values;
  }, []);

  const handleChange = (prop) => (event) => {
    if (elements.includes(prop)) {
      if (isNaN(event.target.value)) {
        return;
      }
      let total = elements.reduce((a, c) => a + Number(values[c]), 0);
      total = total - Number(values[prop]) + Number(event.target.value);
      if (total <= values.persona_qty) {
        setValues({ ...values, [prop]: Number(event.target.value) });
      }
      return;
    }
    if (status.includes(prop)) {
      if (isNaN(event.target.value)) {
        return;
      }
      const total = status.reduce((a, c) => a + Number(values[c]), 0);
      const for_parameter =
        (total - Number(values[prop]) + Number(event.target.value)) / 6;
      setValues({
        ...values,
        [prop]: Number(event.target.value),
        for_parameter: for_parameter,
      });
      return;
    }
    if (prop === "persona_qty") {
      if (isNaN(event.target.value)) {
        return;
      }
      setValues({ ...values, [prop]: Number(event.target.value) });
      return;
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleParameter2Change = (index) => (event) => {
    setCustomParameter2({ ...customParameter2, [index]: event.target.value });
  };

  const handleParameter3Change = (index) => (event) => {
    setCustomParameter3({ ...customParameter3, [index]: event.target.value });
  };

  const handleParameter1Change = (prop, dataType) => (event) => {
    let isValid = false;
    switch (dataType) {
      case "integer":
        if (
          !isNaN(event.target.value) &&
          Number.isInteger(Number(event.target.value)) &&
          Number(event.target.value) >= 0
        ) {
          if (Number(event.target.value) > 2147483647) {
            setValidModal({
              show: true,
              message: "パラメータは最大 2147483647 まで設定可能です。",
            });
            isValid = false;
          } else {
            isValid = true;
          }
        }
        break;
      case "float":
        if (event.target.value[0] === "-") {
          isValid = false;
        } else {
          isValid = true;
        }
        break;
      case "string":
        if (typeof event.target.value === "string") {
          if (event.target.value.length > 100) {
            setValidModal({
              show: true,
              message: "文字数が上限の100文字を超えています。",
            });
            isValid = false;
          } else {
            isValid = true;
          }
        } else {
          isValid = false;
        }
        break;
      default:
        break;
    }
    if (isValid) {
      setParameters1({ ...parameters1, [prop]: event.target.value });
    }
  };

  const fetchProgress = () =>
    fetchData({
      url: `api/job-batches/${batchId}/progress`,
    });

  const { data } = useQuery(
    ["get-persona-progress", batchId],
    () => fetchProgress(),
    {
      onError: (error) => {
        storeErrorModal(error.message);
        if (error.response.status === 404) {
          navigate("/personas");
        }
      },
      onSuccess: (data) => {
        if (data.data.percentage === 100) {
          setIsLoadProgress(false);
        }
      },
      refetchInterval: isLoadProgress ? 5000 : false,
      enabled: isLoadProgress,
    }
  );

  const postPersona = async (data) => {
    const response = await fetchData({
      url: "api/persona_type",
      method: "post",
      data: data,
    });
    return response;
  };

  const { mutate: createPersona, isLoading } = useMutation(
    async (data) => postPersona(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
        }
        storeErrorModal(err);
      },
      onSuccess: async (data) => {
        setBatchId(data.data.batchId);
        // setProgress(80);
        setValues({
          persona_type_name: "",
          creator: "",
          persona_qty: "",
          description: "",
          for_parameter: 20,
          abs_parameter: 20,
          def_parameter: 20,
          month_parameter: 20,
          int_parameter: 20,
          exp_parameter: 20,
          eternal_tree_element: "",
          crimson_flame_element: "",
          adamantine_rock_element: "",
          peerless_steel_element: "",
          heavenly_dew_element: "",
          golden_light_element: "",
          unfathomable_abyss_element: "",
          peerage: "",
          // required_anima_amount: 12.0,
        });
        if (selectedTitle.parametrs) {
          const par = {};
          const parameters = [];
          selectedTitle.parameters.forEach((p) => {
            par[p.id] = "";
            if (p.data_type === "integer") {
              par[p.id] = 0;
            }
            parameters.push = {
              id: p.id,
              name: p.parameter_name,
              value: "",
              unit: p.unit,
              data_type: p.data_type,
            };
          });
          setParameters1(par);
          setCustomParameter1(parameters);
        }
        // setProgress(90);
        setCustomParameter2({ 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" });
        setCustomParameter3({ 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" });
        setImage({ local: "", upload: null });
        // setProgress(100);
        setIsLoadProgress(true);
      },
    }
  );

  const postAnima = async (data) => {
    const response = await fetchData({
      url: "api/persona/calculate-anima",
      method: "post",
      data: data,
    });
    return response;
  };

  const { mutate: calculateAnima } = useMutation(
    async (data) => postAnima(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
        }
        storeErrorModal(err);
      },
      onSuccess: async (data) => {
        setCost(data.data.animaAmount);
      },
    }
  );

  useEffect(() => {
    if (values.persona_qty * cost > user?.tenant.anm_amount) {
      setValidModal({ show: true, message: "ANM残高が不足しています。" });
    }
  }, [cost, user?.tenant.anm_amount, values.persona_qty]);

  useEffect(() => {
    const isRun = status.every((c) => values[c] > 0);
    if (isRun) {
      calculateAnima({
        abs: values.abs_parameter,
        dft: values.def_parameter,
        mnd: values.month_parameter,
        int: values.int_parameter,
        exp: values.exp_parameter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.abs_parameter,
    values.def_parameter,
    values.month_parameter,
    values.int_parameter,
    values.exp_parameter,
  ]);

  useEffect(() => {
    setHideSideBar(true);
  }, [setHideSideBar]);

  useEffect(() => {
    if (!selectedTitle) {
      navigate("/personas/select");
    }
    if (selectedTitle && selectedTitle.parameters) {
      const par = {};
      const parameters = [];
      selectedTitle.parameters.forEach((p) => {
        par[p.id] = "";
        parameters.push({
          id: p.id,
          name: p.parameter_name,
          value: "",
          unit: p.unit,
          data_type: p.data_type,
        });
      });
      setParameters1(par);
      setCustomParameter1(parameters);
    }
  }, [navigate, selectedTitle]);

  let loading = false;
  if (!selectedTitle) {
    loading = true;
  }

  const handleImageChange = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > (1000 ^ 2)) {
        setValidModal({
          show: true,
          message: contents.validation.imageSize,
        });
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImage({ local: imageUrl, upload: file });
    }
  };

  function isFloat(value) {
    if (typeof value === "number" && !isNaN(value)) {
      return !Number.isInteger(value);
    } else {
      return false;
    }
  }

  const handleCreate = async () => {
    if (
      !values.persona_type_name ||
      !values.creator ||
      !values.persona_qty ||
      !image.upload ||
      !values.for_parameter ||
      !values.abs_parameter ||
      !values.def_parameter ||
      !values.month_parameter ||
      !values.int_parameter ||
      !values.exp_parameter
    ) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    let invalid = false;
    let typeInvalid = false;
    let parameters = [];
    if (customParameter1.length > 0) {
      parameters = customParameter1.map((p) => {
        let value = parameters1[p.id];
        if (value === "") {
          invalid = true;
        }
        const type = p.data_type;
        switch (type) {
          case "integer":
            if (value[value.length - 1] === "." || Number(value) <= 0) {
              typeInvalid = true;
            } else {
              value = Number(value);
            }
            break;
          case "float":
            if (!isFloat(Number(value))) {
              typeInvalid = true;
            } else {
              value = Number(value);
            }
            break;
          case "string":
            if (!isNaN(value)) {
              typeInvalid = true;
            }
            break;
          default:
            break;
        }
        return {
          ...p,
          value: value,
        };
      });
    }
    if (invalid) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    if (typeInvalid) {
      setValidModal({
        show: true,
        message: "カスタムパラメータの値が不正です。",
      });
      return;
    }

    let total = elements.reduce((a, c) => a + Number(values[c]), 0);
    if (total !== values.persona_qty) {
      setValidModal({
        show: true,
        message: "発行数がelementsの合計数と一致していません。",
      });
      return;
    }
    if (values.persona_qty * cost > user?.tenant.anm_amount) {
      setValidModal({ show: true, message: "ANM残高が不足しています。" });
      return;
    }
    setShowLoadingModal(true);
    const persona = {
      ...values,
      aura_amount: selectedTitle.aura_amount,
      group_id: selectedTitle.id,
      custom_parameter1: parameters,
      custom_parameter2: Object.values(customParameter2),
      custom_parameter3: Object.values(customParameter3),
      required_anima_amount: cost,
    };
    if (image.upload) {
      // setProgress(20);
      const formData = new FormData();
      formData.append("file", image.upload);
      formData.append("type", "ipfs");
      const res = await fetchFormData({
        url: "api/upload",
        data: formData,
      });
      persona.image_uri = res.data.url;
      persona.image_hash = res.data.ipfs.Hash;
      // setProgress(40);
    } else {
      return;
    }
    createPersona(persona);
  };

  return (
    <ContentWrapper
      titletopmarign="true"
      title={strings.formatString(contents.title)}
      topSpace={
        <div className="d-flex justify-content-end mb-6-px gap-4">
          <CancelButton
            text={contents.cancel_btn}
            width="173px"
            height="40px"
            onClick={() => setShowCancelModal(true)}
          />
          <Button
            text={contents.keep_btn}
            width="173px"
            height="40px"
            onClick={() => {
              setShowKeepModal(true);
            }}
            disabled={loading}
          />
          <Button
            text={contents.issue_btn}
            width="173px"
            height="40px"
            onClick={handleCreate}
            disabled={!loading && isLoading}
          />
        </div>
      }
      marginLeft="12px"
    >
      {loading ? (
        <em>Loading...</em>
      ) : (
        <div className="h-100 mx-36-px my-10-px">
          <section className="row mb-4 w-95">
            <p className="font-notosans-jp label-text col-2">
              {contents.title_name}
            </p>
            <p className="fs-17-px text-gray col">{selectedTitle.name}</p>
          </section>
          <section className="row mb-4 align-items-center w-80">
            <label className="font-notosans-jp label-text col-3">
              {contents.name}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
            </label>
            <div className="d-flex align-items-center col" style={{ gap: 20 }}>
              <TextInput
                width="415px"
                inputClass="left-placeholder fs-20-px"
                inactive
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.name_placeholder}
                value={values.persona_type_name}
                onChange={handleChange("persona_type_name")}
              />
            </div>
          </section>
          <section className="row mb-4 align-items-center w-80">
            <label className="font-notosans-jp label-text col-3">
              {contents.creator_name}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
            </label>
            <div className="d-flex align-items-center col" style={{ gap: 20 }}>
              <TextInput
                width="415px"
                inputClass="left-placeholder fs-20-px"
                inactive
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.creator_name_placeholder}
                value={values.creator}
                onChange={handleChange("creator")}
              />
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text">
              {contents.quantity}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
            </label>
            <div className="d-flex align-items-center my-20-px">
              <TextInput
                width="230px"
                inputClass="fs-20-px center-placeholder"
                inactive
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.quantity_placeholder}
                value={values.persona_qty}
                onChange={handleChange("persona_qty")}
              />
            </div>
          </section>
          <section className="d-flex mb-5">
            <div className="">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="font-notosans-jp label-text">
                  {contents.image}
                  <span className="text-danger required-label ml-10-px fs-18-px">
                    *
                  </span>
                </p>
                <input
                  type="file"
                  id="image"
                  style={{ visibility: "hidden", display: "none" }}
                  onChange={handleImageChange}
                />
                <Button
                  text={contents.upload_btn}
                  width="159.1px"
                  height="40px"
                  onClick={() => {
                    document.getElementById("image").click();
                  }}
                />
              </div>
              {image.local ? (
                <div className="persona-img-container img-container">
                  <img src={image.local} alt="" />
                </div>
              ) : (
                <div className="persona-img-container d-flex justify-content-center align-items-center">
                  <ImgPlaceholderIcon />
                </div>
              )}
            </div>
            <div className="ms-5 w-100">
              <p
                className="font-notosans-jp label-text mb-4"
                style={{ height: 40, alignContent: "center" }}
              >
                {contents.parameter}
                <span className="fs-14-px text-light-primary ml-40-px">
                  {contents.parameter_secondary}
                </span>
              </p>
              <div className="ml-10-px">
                {selectedTitle.parameters &&
                  selectedTitle.parameters.length > 0 &&
                  parameters1 &&
                  selectedTitle.parameters.map((p, index) => (
                    <div className="d-flex my-3 align-items-center" key={index}>
                      <label className="label-text" style={{ width: 270 }}>
                        {p.parameter_name}
                        <span className="text-danger required-label ml-10-px fs-18-px">
                          *
                        </span>
                      </label>
                      <div className="d-flex align-items-center gap-20-px">
                        <TextInput
                          width="270px"
                          inputClass="text-center fs-17-px large-placeholder text-form-control"
                          inactive
                          placeholder="No Data"
                          isLeftIcon
                          leftIcon={<PenIcon />}
                          onChange={handleParameter1Change(p.id, p.data_type)}
                          value={parameters1[p.id]}
                        />
                        <p className="fs-17-px text-dark-gray">
                          {p.unit ?? "単位"}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.description}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.description_secondary}
              </span>
            </label>
            <TextInput
              className="ml-30-px"
              isTextArea
              width="965px"
              height="118px"
              inputClass="left-placeholder"
              inactive
              placeholder={contents.description_placeholder}
              value={values.description}
              onChange={handleChange("description")}
            />
          </section>
          <section className="mb-5">
            <p className="font-notosans-jp label-text mb-4">
              {contents.status}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.status_secondary}
              </span>
            </p>
            <div className="row pl-30-px">
              <div className="col-2 ps-0">
                <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                  <FORIcon /> FOR
                </label>
                <TextInput
                  width="145px"
                  inputClass="font-ethno-normal left-placeholder text-light fs-20-px text-center"
                  inactive
                  value={values.for_parameter}
                  disabled
                />
              </div>
              <div className="col-2 ps-0">
                <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                  <ABSIcon /> ABS
                </label>
                {/* <TextInput
                  width="110px"
                  inputClass="text-form-control-value left-placeholder text-light"
                  inactive
                  isLeftIcon
                  leftIcon={<PenIcon />}
                  value={values.abs_parameter}
                  onChange={handleChange("abs_parameter")}
                /> */}
                <SelectInput
                  id="abs"
                  value={values.abs_parameter}
                  onChange={handleChange("abs_parameter")}
                  options={statusValues}
                  placeholder=""
                  styles={{ width: 145 }}
                  className="text-light fs-20-px font-ethno-normal"
                />
              </div>
              <div className="col-2 ps-0">
                <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                  <DFTIcon /> DFT
                </label>
                {/* <TextInput
                  width="110px"
                  inputClass="text-form-control-value left-placeholder text-light"
                  inactive
                  isLeftIcon
                  leftIcon={<PenIcon />}
                  value={values.def_parameter}
                  onChange={handleChange("def_parameter")}
                /> */}
                <SelectInput
                  id="abs"
                  value={values.def_parameter}
                  onChange={handleChange("def_parameter")}
                  options={statusValues}
                  placeholder=""
                  styles={{ width: 145 }}
                  className="text-light fs-20-px font-ethno-normal"
                />
              </div>
              <div className="col-2 ps-0">
                <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                  <MNDIcon /> MND
                </label>
                {/* <TextInput
                  width="110px"
                  inputClass="text-form-control-value left-placeholder text-light"
                  inactive
                  isLeftIcon
                  leftIcon={<PenIcon />}
                  value={values.month_parameter}
                  onChange={handleChange("month_parameter")}
                /> */}
                <SelectInput
                  id="mdn"
                  value={values.month_parameter}
                  onChange={handleChange("month_parameter")}
                  options={statusValues}
                  placeholder=""
                  styles={{ width: 145 }}
                  className="text-light fs-20-px font-ethno-normal"
                />
              </div>
              <div className="col-2 ps-0">
                <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                  <INTIcon /> INT
                </label>
                {/* <TextInput
                  width="110px"
                  inputClass="text-form-control-value left-placeholder text-light"
                  inactive
                  isLeftIcon
                  leftIcon={<PenIcon />}
                  value={values.int_parameter}
                  onChange={handleChange("int_parameter")}
                /> */}
                <SelectInput
                  id="int"
                  value={values.int_parameter}
                  onChange={handleChange("int_parameter")}
                  options={statusValues}
                  placeholder=""
                  styles={{ width: 145 }}
                  className="text-light fs-20-px font-ethno-normal"
                />
              </div>
              <div className="col-2 ps-0">
                <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                  <EXPIcon /> EXP
                </label>
                {/* <TextInput
                  width="110px"
                  inputClass="text-form-control-value left-placeholder text-light"
                  inactive
                  isLeftIcon
                  leftIcon={<PenIcon />}
                  value={values.exp_parameter}
                  onChange={handleChange("exp_parameter")}
                /> */}
                <SelectInput
                  id="exp"
                  value={values.exp_parameter}
                  onChange={handleChange("exp_parameter")}
                  options={statusValues}
                  placeholder=""
                  styles={{ width: 145 }}
                  className="text-light fs-20-px font-ethno-normal"
                />
              </div>
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text">
              {contents.cost}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.cost_secondary}
              </span>
            </label>
            <p
              className="font-ethno-normal fs-20-px text-highlight ml-30-px my-3"
              style={{ height: 30 }}
            >
              {cost ?? ""}
            </p>
            <p className="text-dark-gray">
              <span className="fs-12-px font-notosans-jp-light">
                {contents.cost_current}
              </span>
              <span className="fs-16-px font-ethno-light ml-10-px d-inline-block">
                ／ {user?.tenant?.anm_amount}
              </span>
            </p>
          </section>
          {/* <section className="mb-5">
          <p className="font-notosans-jp label-text mb-4">
            {contents.absorb.label}
            <span className="fs-14-px text-light-primary ml-50-px">
              {contents.absorb_secondary}
            </span>
          </p>
          <div className="pl-30-px d-flex">
            <label className="label-text fs-18-px w-30">
              <EatIcon className="mr-10-px" /> {contents.absorb.contract}
            </label>
            <div>
              <span
                className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                style={{ width: 50 }}
              >
                OFF
              </span>
              <Switch id="absorb" />
            </div>
          </div>
        </section>
        <section className="mb-5">
          <p className="font-notosans-jp label-text mb-4">
            {contents.draw_chain.label}
            <span className="fs-14-px text-light-primary ml-50-px">
              {contents.draw_chain_secondary}
            </span>
          </p>
          <div className="d-flex pl-30-px">
            <label className="label-text fs-18-px col-4 w-30">
              <DrawChainIcon className="mr-5-px" />
              {contents.draw_chain.contract}
            </label>
            <div className="">
              <span
                className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                style={{ width: 50 }}
              >
                ON
              </span>
              <Switch id="drawChain" />
            </div>
          </div>
          <section className="ml-30-px pl-33-px">
            <div className="d-flex mt-4 align-items-center">
              <label className="label-text fs-18-px w-29">
                {contents.draw_chain.id}
              </label>
              <div className="p-0">
                <p className="font-ethno-light fs-18-px text-gray">
                  <span className="fs-19-px">
                    128849281025 <CopyIcon />
                  </span>
                  <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                    {contents.token_id_secondary}
                  </span>
                </p>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <label className="label-text fs-18-px w-25">
                {contents.draw_chain.name}
              </label>
              <div className="p-0">
                <TextInput
                  width="415px"
                  inputClass="left-placeholder text-form-control"
                  inactive
                  isLeftIcon={true}
                  leftIcon={<PenIcon />}
                  placeholder={contents.draw_chain.name_placeholder}
                />
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <label className="label-text fs-18-px d-flex pr-0-px w-25">
                <span>{contents.draw_chain.required}</span>
                <span
                  className="fs-20-px d-flex align-items-center"
                  style={{ marginLeft: "10%" }}
                >
                  <FORIcon /> FOR
                </span>
              </label>
              <div className="p-0 d-flex align-items-center">
                <TextInput
                  className="d-inline-block"
                  width="111px"
                  inputClass="left-placeholder text-form-control font-ethno-normal text-center text-gray"
                  inactive
                  isLeftIcon={true}
                  leftIcon={<PenIcon />}
                  placeholder={contents.draw_chain.placeholder}
                />
                <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                  {contents.draw_chain.required_secondary}
                </span>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <label className="col-3 label-text fs-18-px w-25">
                {contents.draw_chain.no}
              </label>
              <div className="p-0 d-flex align-items-center">
                <TextInput
                  className="d-inline-block"
                  width="111px"
                  inputClass="left-placeholder text-form-control font-ethno-normal text-center text-gray"
                  inactive
                  isLeftIcon={true}
                  leftIcon={<PenIcon />}
                  placeholder={contents.draw_chain.placeholder}
                />
                <span className="font-notosans-jp fs-17-px text-gray ml-10-px">
                  {contents.draw_chain.no_secondary}
                </span>
              </div>
            </div>
            <div className="d-flex mt-4">
              <label className="label-text fs-18-px pt-10-px w-25">
                {contents.draw_chain.description}
              </label>
              <div className="p-0 w-75">
                <TextInput
                  isTextArea
                  width="710px"
                  height="118px"
                  inputClass="left-placeholder text-form-control fs-17-px"
                  inactive
                  isLeftIcon={true}
                  leftIcon={<PenIcon />}
                  placeholder={contents.draw_chain.description_placeholder}
                />
              </div>
            </div>
          </section>
        </section> */}
          <section className="mb-5">
            <p className="font-notosans-jp label-text mb-4">
              {contents.element.label}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.element_secondary}
              </span>
            </p>
            <div className="row pl-30-px">
              <div className="col-4">
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <DropOfHeavenIcon className="mr-10-px" />
                    天の雫
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.heavenly_dew_element}
                      onChange={handleChange("heavenly_dew_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <EverGreenTreeIcon className="mr-10-px" />
                    常磐なる樹
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.eternal_tree_element}
                      onChange={handleChange("eternal_tree_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <SolidRockIcon className="mr-10-px" />
                    堅牢な岩
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.adamantine_rock_element}
                      onChange={handleChange("adamantine_rock_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <CrimsonFlameIcon className="mr-10-px" />
                    紅蓮の焔
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.crimson_flame_element}
                      onChange={handleChange("crimson_flame_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <UnrivaledSteelIcon className="mr-10-px" />
                    無双の鋼
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.peerless_steel_element}
                      onChange={handleChange("peerless_steel_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <GoldenLightIcon className="mr-10-px" />
                    黄金の光
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.golden_light_element}
                      onChange={handleChange("golden_light_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <BottomLessAbyssIcon className="mr-10-px" />
                    底知れぬ深淵
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.unfathomable_abyss_element}
                      onChange={handleChange("unfathomable_abyss_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.place_of_birth}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.place_of_birth_secondary}
              </span>
            </label>
            <p className="font-ethno-normal fs-19-px text-gray ml-30-px">
              {user?.tenant?.birthplace}
            </p>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.peerage}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.peerage_secondary}
              </span>
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.peerage_third}
              </span>
            </label>
            <SelectInput
              id="peerage"
              value={values.peerage}
              onChange={handleChange("peerage")}
              options={{
                Duke: "duke",
                Marquess: "marquess",
                Count: "count",
                Viscount: "viscount",
                Baron: "baron",
              }}
              placeholder=""
              styles={{ width: 307.92, marginLeft: 30 }}
              className="font-ethno-normal text-gray fs-17-px"
            />
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.parameter_ability}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.parameter_ability_secondary}
              </span>
            </label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1.5rem",
              }}
              className="ml-30-px"
            >
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[0]}
                onChange={handleParameter2Change(0)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[1]}
                onChange={handleParameter2Change(1)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[2]}
                onChange={handleParameter2Change(2)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[3]}
                onChange={handleParameter2Change(3)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[4]}
                onChange={handleParameter2Change(4)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[5]}
                onChange={handleParameter2Change(5)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.parameter_title}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.parameter_title_secondary}
              </span>
            </label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1.5rem",
              }}
              className="ml-30-px"
            >
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[0]}
                onChange={handleParameter3Change(0)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[1]}
                onChange={handleParameter3Change(1)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[2]}
                onChange={handleParameter3Change(2)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[3]}
                onChange={handleParameter3Change(3)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[4]}
                onChange={handleParameter3Change(4)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[5]}
                onChange={handleParameter3Change(5)}
                isLeftIcon={true}
                leftIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
            </div>
          </section>
        </div>
      )}
      <InfoModal
        isOpen={showKeepModal}
        onAction={() => setShowKeepModal(false)}
      >
        保存しました。
      </InfoModal>
      <ConfirmModal
        isOpen={showCancelModal}
        onConfirm={() => {
          setShowCancelModal(false);
          setTimeout(() => {
            navigate("/personas");
          }, 500);
        }}
        onCancel={() => setShowCancelModal(false)}
      >
        一部の変更は保存されていません。
        <br />
        変更を保存しますか？
      </ConfirmModal>
      <LoadingModal
        isOpen={showLoadingModal}
        onAction={() => {
          setShowLoadingModal(false);
          setTimeout(() => {
            navigate("/personas");
          }, 500);
        }}
        progress={data?.data?.percentage ?? 0}
      ></LoadingModal>
      <InfoModal
        isOpen={validModal.show}
        onAction={() => {
          setValidModal({ show: false, message: "" });
        }}
      >
        {validModal.message}
      </InfoModal>
    </ContentWrapper>
  );
};

export default PersonaCreate;
