import { useNavigate } from "react-router-dom";
import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import Button, { CancelButton } from "../../components/Button";
import { SelectInput, TextInput } from "../../components/Form";
import { useState, useRef, useContext } from "react";
import { PenIcon, PlusIcon } from "../../assets/icons";
import { DeleteIcon } from "../../components/common/Icon";
import { ConfirmModal, InfoModal } from "../../components/Modal";
import { useMutation, useQuery } from "react-query";
import { fetchData } from "../../services/fetch";
import { storeErrorModal } from "../../services/storage";
import { AuthContext } from "../../context/authContext";

const TitleCreate = () => {
  const contents = translations.titleCreate;
  const navigate = useNavigate();
  const parameter_index = useRef(0);
  const { user, language } = useContext(AuthContext);

  const [parameters, setParameters] = useState([
    // {
    //   index: 0,
    //   data_type: "",
    //   parameter_name: "",
    //   unit: "",
    //   parameter_no: 0,
    //   min_value: 0,
    //   max_value: 0,
    // },
  ]);
  const [showKeepModal, setShowKeepModal] = useState(false);
  const [validModal, setValidModal] = useState({ show: false, message: "" });

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showParamDeleteModal, setShowParamDeleteModal] = useState(false);
  const [parameterIndex, setParameterIndex] = useState();
  const [values, setValues] = useState({
    name: "",
    threshold: 1,
    aura_amount: "",
  });

  const handleChange = (prop) => (event) => {
    if (prop === "aura_amount") {
      if (isNaN(event.target.value)) {
        return;
      }
      if (event.target.value === "0") {
        setValues({ ...values, [prop]: "" });
        return;
      }
      if (event.target.value > 2147483647) {
        setValidModal({
          show: true,
          message: "AURA ／Gia Changeは最大2147483647まで<br />設定可能です",
        });
        return;
      }
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleParameterChange = (index, prop) => (event) => {
    const parameter = parameters[index];
    setParameters((oldParameters) =>
      oldParameters.map((p) =>
        p.index === parameter.index
          ? { ...parameter, [prop]: event.target.value }
          : p
      )
    );
  };

  const postTitle = async (data) => {
    const response = await fetchData({
      url: "api/persona_group",
      method: "post",
      data: data,
    });
    return response;
  };

  const { isLoading, data: response } = useQuery(
    ["get-id"],
    () => fetchData({ url: "/api/persona_group/create/data" }),
    {
      onError: (err) => {
        storeErrorModal(err);
      },
    }
  );
  const { mutate: createTitle, isLoading: isLoadingCreate } = useMutation(
    async (data) => postTitle(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
        }
        storeErrorModal(err);
      },
      onSuccess: async () => {
        setValues({
          name: "",
          threshold: 1,
          aura_amount: "",
        });
        setParameters([
          {
            index: 0,
            data_type: "",
            parameter_name: "",
            unit: "",
            parameter_no: 0,
            min_value: 0,
            max_value: 0,
          },
        ]);
        setShowKeepModal(true);
      },
    }
  );

  const handleAddParameter = () => {
    if (parameters.length < 8) {
      parameter_index.current += 1;
      let obj = {
        index: parameter_index.current,
        data_type: "",
        parameter_name: "",
        unit: "",
        parameter_no: 0,
        min_value: 0,
        max_value: 0,
      };
      setParameters((parameters) => [...parameters, obj]);
    }
  };

  const handleDeleteParameter = (index) => {
    setParameters((currentParameters) =>
      currentParameters.filter((parameter) => parameter.index !== index)
    );
  };

  const group_no = !isLoading && response.data ? response.data.group_no : null;

  const handleCreate = () => {
    const title = {
      ...values,
      group_no: group_no,
      tenant_id: user.tenant?.id,
      parameters: parameters,
    };
    if (!values.name || !values.aura_amount) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    if (
      parameters.length > 0 &&
      !parameters.every((p) => p.data_type && p.parameter_name)
    ) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    createTitle(title);
  };

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={strings.formatString(contents.title)}
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-4">
            <CancelButton
              text={contents.cancel_btn}
              width="173px"
              height="40px"
              onClick={() => setShowCancelModal(true)}
            />
            <Button
              text={contents.keep_btn}
              width="173px"
              height="40px"
              onClick={handleCreate}
              disabled={isLoadingCreate}
            />
          </div>
        }
        marginLeft="12px"
      >
        {isLoading ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <div className="d-flex w-100 mt-30-px" style={{ gap: 23 }}>
              <div className="d-flex align-items-center" style={{ gap: 40 }}>
                <label className="font-notosans-jp label-text">
                  {contents.name}
                  <span className="text-danger required-label ml-10-px fs-18-px">
                    *
                  </span>
                </label>
                <div className="d-flex align-items-center" style={{ gap: 20 }}>
                  <TextInput
                    width="348.54px"
                    inputClass="left-placeholder fs-20-px"
                    inactive
                    isLeftIcon={true}
                    leftIcon={<PenIcon />}
                    placeholder={contents.name_placeholder}
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center" style={{ gap: 70 }}>
                <p className="font-notosans-jp label-text">{contents.id}</p>
                <p>
                  <span className="font-ethno-light fs-19-px text-gray">
                    {group_no}
                  </span>
                  <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                    {contents.id_secondary}
                  </span>
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center mt-30-px"
              style={{ gap: 46 }}
            >
              <label className="font-notosans-jp label-text">
                {contents.value}
                <span className="text-danger required-label ml-10-px fs-18-px">
                  *
                </span>
              </label>
              <div className="d-flex align-items-center" style={{ gap: 15 }}>
                <TextInput
                  width="266.28px"
                  inputClass="text-form-control-value left-placeholder"
                  inactive
                  isLeftIcon={true}
                  leftIcon={<PenIcon />}
                  placeholder={contents.value_placeholder}
                  value={values.aura_amount}
                  onChange={handleChange("aura_amount")}
                  type="tel"
                />
                <span className="text-light-primary fs-14-px">
                  {contents.value_secondary}
                </span>
              </div>
            </div>
            <div className="d-flex my-39-px" style={{ gap: 30 }}>
              <p className="label-text">{contents.custom_parameter}</p>
              <div>
                <button
                  className="button-plus"
                  onClick={handleAddParameter}
                  disabled={parameters.length >= 8}
                >
                  <PlusIcon />
                </button>
                <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                  {contents.custom_parameter_secondary}
                </span>
              </div>
            </div>
            <section className="mb-10-px">
              <div className="d-flex" style={{ gap: 20 }}>
                <p
                  className="parameter-label-text"
                  style={{
                    width: 196,
                    letterSpacing:
                      language === "English" ? "-0.1em" : "-0.086em",
                  }}
                >
                  {contents.parameter_type}
                  <span className="fs-12-px">
                    {contents.parameter_type_secondary}
                  </span>
                </p>
                <p
                  className="parameter-label-text fs-14-px"
                  style={{ width: 269.77 }}
                >
                  {contents.parameter_name}
                </p>
                <p
                  className="parameter-label-text fs-15-px"
                  style={{
                    // width: 180.46,
                    letterSpacing: "-0.034em",
                    position: "relative",
                  }}
                >
                  {contents.parameter_unit}
                  <span className="text-light-primary fs-14-px ml-10-px">
                    {contents.parameter_unit_secondary}
                  </span>
                </p>
              </div>
              {parameters.map((parameter, key) => (
                <div
                  className="d-flex mt-10-px"
                  style={{ gap: 20 }}
                  key={parameter.index}
                >
                  <div className="">
                    <SelectInput
                      id={parameter.index}
                      value={parameter.data_type}
                      onChange={handleParameterChange(key, "data_type")}
                    />
                  </div>
                  <div className="">
                    <TextInput
                      width="269.77px"
                      inactive
                      placeholder={contents.parameter_name_placeholder}
                      inputClass="left-placeholder fs-20-px"
                      value={parameter.parameter_name}
                      onChange={handleParameterChange(key, "parameter_name")}
                    />
                  </div>
                  <div className="">
                    <TextInput
                      width="180.46px"
                      inactive
                      placeholder={contents.parameter_unit_placeholder}
                      inputClass="left-placeholder fs-20-px"
                      value={parameter.unit}
                      onChange={handleParameterChange(key, "unit")}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setParameterIndex(parameter.index);
                      setShowParamDeleteModal(true);
                    }}
                    className="delete-icon"
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ))}
            </section>
          </div>
        )}

        <InfoModal
          isOpen={showKeepModal}
          onAction={() => {
            setShowKeepModal(false);
            setTimeout(() => {
              navigate("/titles");
            }, [500]);
          }}
        >
          保存しました。
        </InfoModal>
        <InfoModal
          isOpen={validModal.show}
          onAction={() => {
            setValidModal({ show: false, message: "" });
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: validModal.message }}></div>
        </InfoModal>
        <ConfirmModal
          isOpen={showCancelModal}
          onConfirm={() => {
            setShowCancelModal(false);
            setTimeout(() => {
              navigate("/titles");
            }, 500);
          }}
          onCancel={() => setShowCancelModal(false)}
        >
          一部の変更は保存されていません。
          <br />
          変更を保存しますか？
        </ConfirmModal>
        <ConfirmModal
          isOpen={showParamDeleteModal}
          onConfirm={() => {
            handleDeleteParameter(parameterIndex);
            setParameterIndex(null);
            setShowParamDeleteModal(false);
          }}
          onCancel={() => setShowParamDeleteModal(false)}
        >
          パラメータを削除しますか？
        </ConfirmModal>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default TitleCreate;
